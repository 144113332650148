var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header',{attrs:{"propsObj":_vm.page}}),_c('HomeSchoolBar',_vm._b({},'HomeSchoolBar',{
                showText: true,
				title_left: _vm.page.bar_title_left,
				link_text_left: _vm.page.bar_link_text_left,
				link_left: _vm.page.bar_link_left,
				content_left: _vm.page.bar_content_left,
				title_right: _vm.page.bar_title_right,
				link_text_right: _vm.page.bar_link_text_right,
				link_right: _vm.page.bar_link_right,
				content_right: _vm.page.bar_content_right,
			},false)),_c('Intro',{attrs:{"title":_vm.page.intro_title,"content":_vm.page.intro_content}}),_vm._m(0),_c('div',{staticClass:"container bg-light-pink mt-5 py-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 offset-md-3 text-center"},[_c('h3',{staticClass:"fg-red"},[_vm._v("LATEN WE CONTACT HOUDEN!")]),_c('p',[_vm._v("Mis niets! Schrijf je hier in voor mijn tweemaandelijkse digitale nieuwsbrief.")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"text","placeholder":"Mailadres..."},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('button',{staticClass:"bg-apricot text-white",on:{"click":_vm.sendMail}},[_vm._v("VERSTUUR")])])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 offset-md-2 col-lg-6 offset-lg-3"},[_c('SocialMedia')],1)])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container px-0"},[_c('div',{staticClass:"frameContainer"})])}]

export { render, staticRenderFns }